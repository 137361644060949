import React from 'react';
import { TimelineMax, CSSPlugin } from 'gsap/all';
import ScrollLock from 'react-scrolllock';
import Flip from '../plugins/Flip';
import * as Constants from '../components/Constants';

export default class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.loadingNumbers = null;
    this.loaderProgress = null;
    this.timelineLoading = null;

    this.company = null;
    this.companyPerson = null;
    this.companyLogo = null;

    this.standardSize = null;
  }

  componentDidMount() {
    this.timelineLoading = new TimelineMax({
      onComplete: () => this.props.updateRenderStates('intro', false),
    })
      .to(this.loadingNumbers, 0.8, { ease: 'Mo', yPercent: (100 / 11) * 1, delay: 0.5 }) // delay used to be 0.25
      .to(this.loaderProgress, 0.8, { ease: 'Mo', height: '10vh' }, '-=0.8')
      .to(this.loadingNumbers, 0.6, { ease: 'Mo', yPercent: (100 / 11) * 2 })
      .to(this.loaderProgress, 0.6, { ease: 'Mo', height: '20vh' }, '-=0.6')
      .to(this.loadingNumbers, 0.4, { ease: 'Mo', yPercent: (100 / 11) * 3 })
      .to(this.loaderProgress, 0.4, { ease: 'Mo', height: '30vh' }, '-=0.4')
      .to(this.loadingNumbers, 0.3, { ease: 'Mo', yPercent: (100 / 11) * 4 })
      .to(this.loaderProgress, 0.3, { ease: 'Mo', height: '40vh' }, '-=0.3')
      .to(this.loadingNumbers, 0.2, { ease: 'Mo', yPercent: (100 / 11) * 5 })
      .to(this.loaderProgress, 0.2, { ease: 'Mo', height: '50vh' }, '-=0.2')
      .to(this.loadingNumbers, 0.2, { ease: 'Mo', yPercent: (100 / 11) * 6 })
      .to(this.loaderProgress, 0.2, { ease: 'Mo', height: '60vh' }, '-=0.2')
      .to(this.loadingNumbers, 0.2, { ease: 'Mo', yPercent: (100 / 11) * 7 })
      .to(this.loaderProgress, 0.2, { ease: 'Mo', height: '70vh' }, '-=0.2')
      .to(this.loadingNumbers, 0.3, { ease: 'Mo', yPercent: (100 / 11) * 8 })
      .to(this.loaderProgress, 0.3, { ease: 'Mo', height: '80vh', backgroundColor: '#f1ae21' }, '-=0.3')
      .to(this.loadingNumbers, 0.4, { ease: 'Mo', yPercent: (100 / 11) * 9 })
      .to(this.loaderProgress, 0.4, { ease: 'Mo', height: '90vh', backgroundColor: '#e76551' }, '-=0.4')
      .to(this.loadingNumbers, 0.8, { ease: 'Mo', yPercent: (100 / 11) * 10 })
      .to(this.loaderProgress, 0.8, { ease: 'Mo', height: '100vh', backgroundColor: '#f2e7de' }, '-=0.8')
      // left: 0,
      .to(this.company, 0, { display: 'block' })
      .to(this.loaderProgress, 0.8, {
        ease: 'Mo',
        top: 0,
        bottom: 'auto',
        marginLeft: 0,
        scaleX: 1,
        delay: 0.1,
      })
      .to(this.companyPerson, 0.8, { ease: 'Mo', y: 0 })
      .to(this.companyLogo, 0.8, { ease: 'Mo', y: 0, delay: 0.1 }, '-=0.8')
      // .to({}, 80, {}) // DELETE
      .to(this.loadingNumbers, 0, { display: 'none' })
      // SEQUENCE 12 -> 13
      .add(() => this.props.updateRenderStates('home', true))
      .add(() => this.props.updateRenderStates('base', true))
      .add(() => this.props.updateRenderStates('projects', true))
      .add(() => this.props.updateRenderStates('caseStudy', true))
      // .add(() => this.props.renderHome())
      // .to({}, 10, {})
      // .delay(0.2)
      .to(this.companyPerson, 0.8, { ease: 'Mo', y: -this.standardSize.clientHeight, delay: 0.2 })
      .to(
        this.companyLogo,
        0.8,
        { ease: 'Mo', y: -this.standardSize.clientHeight * 1.875, delay: 0.1 },
        '-=0.8'
      )
      .to(this.loaderProgress, 0.8, { ease: 'Mo', scaleY: 0, delay: 0.32 }, '-=0.8')
      .to(this.company, 0, { display: 'none' });

    // console.log('STANDARD SIZE', this.standardSize.clientHeight);
    // .to(this.company, 0.8, { ease: 'Mo', height: 0, delay: 0.32 }, '-=0.8');
    // .to(this.loaderProgress, 0.8, { ease: 'Mo', height: 0, delay: 0.32 }, '-=0.8');

    //       .add(() => {
    //         let flip = new FLIP({
    //           element: this.loaderProgress,
    //           duration: 800,
    //           play: 'GSAP'
    //         });
    //
    //         // First position & opacity.
    //         flip.first();
    //
    //         // Apply the 'end' class and snapshot the last position & opacity.
    //         flip.last('.intro__progress-bar--animated');
    //
    //         // Move and fade the element back to the original position.
    //         flip.invert();
    //
    //         // Play it forwards.
    //         flip.play();
    //       });
    // .to(this.loaderProgress, 0.8, {
    //   ease: 'Mo',
    //   width: '100vw',
    //   left: 0,
    //   top: 0,
    //   bottom: 'auto',
    //   marginLeft: 0,
    //   delay: 0.1
    // })
    // console.log('height: ', this.standardSize.clientHeight);
    // console.log(this.loaderProgress.getBoundingClientRect());
    //this.timelineLoading.play();
  }

  render() {
    return (
      <>
        <ScrollLock />
        <section className="intro">
          <div className="intro__loader">
            <div className="intro__loader__numbers" ref={(div) => (this.loadingNumbers = div)}>
              <h2 className="intro__loader__numbers__number">Hundred</h2>
              <h2 className="intro__loader__numbers__number">90</h2>
              <h2 className="intro__loader__numbers__number korean">팔십</h2>
              <h2 className="intro__loader__numbers__number">70.0</h2>
              <h2 className="intro__loader__numbers__number">Sixty</h2>
              <h2 className="intro__loader__numbers__number">Fifty</h2>
              <h2 className="intro__loader__numbers__number">Fourty</h2>
              <h2 className="intro__loader__numbers__number">30</h2>
              <h2 className="intro__loader__numbers__number">20</h2>
              <h2 className="intro__loader__numbers__number">10</h2>
              <h2 className="intro__loader__numbers__number" ref={(h2) => (this.standardSize = h2)}>
                Zero
              </h2>
            </div>
          </div>
          <div className="company" ref={(div) => (this.company = div)}>
            <div className="company__content">
              <div className="company__person">
                <h3 className="company__person__content" ref={(h3) => (this.companyPerson = h3)}>
                  To. <span>{Constants.person.name}</span> at
                </h3>
              </div>
              <div className="company__logo">
                <img
                  className="company__logo__content"
                  ref={(img) => (this.companyLogo = img)}
                  src={`../assets/${Constants.person.logo}`}
                />
              </div>
            </div>
          </div>
          <div className="intro__progress-bar" ref={(div) => (this.loaderProgress = div)} />
        </section>
      </>
    );
  }
}
