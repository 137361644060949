import PropTypes from 'prop-types';

export const contextTypesAll = {
	router: PropTypes.shape({
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
			replace: PropTypes.func.isRequired
		}).isRequired,
		staticContext: PropTypes.object
	}).isRequired,
	updateTransitionDelay: PropTypes.func.isRequired,
	transitionDelay: PropTypes.number.isRequired
};

export const contextTypesRouterOnly = {
	router: PropTypes.shape({
		history: PropTypes.shape({
			push: PropTypes.func.isRequired,
			replace: PropTypes.func.isRequired
		}).isRequired,
		staticContext: PropTypes.object
	}).isRequired
};

export const contextTypesTransitionOnly = {
	updateTransitionDelay: PropTypes.func.isRequired,
	transitionDelay: PropTypes.number.isRequired,
	isPopping: PropTypes.bool.isRequired,
	canForwardPop: PropTypes.bool.isRequired
};

export const person = {
	name: 'Jared Erondu',
	company: 'Lattice',
	logo: 'lattice-logo.svg'
};

/*** BELOW ARE ALL OLD, SOME MAY BE UNUSED ***/

export function getScrollOffset() {
	return window.outerWidth / 5;
}
